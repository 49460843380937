import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { ConstantsService } from '../../services/utils/constants.service';
import { ClientService } from '../../services/client/client.service';
import { DeviceService } from '../../services/device/device.service';
import { RoomService } from '../../services/room/room.service';
import { DailyReadingsService } from '../../services/daily-readings/daily-readings.service';
import Swal from 'sweetalert2';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-daily-readings',
    templateUrl: './daily-readings.component.html',
    styleUrls: ['./daily-readings.component.css'],
})
export class DailyReadingsComponent implements OnInit {
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allRooms: any[] = [];
    public allRoomsNames: any[] = [];
    public allApartmentsNames: any[] = [];
    public allClients: any[] = [];
    public allClientsNames: string[] = [];
    public allDevices: any[] = [];
    public toggle_chart: boolean = false;
    private mSCDatatable: any;
    public allMSCReadings: any[] = [];
    public selectedMscReadings: any[] = [];
    public msc_chart_url_savings: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl("");

    constructor(
        private userService: UserService,
        private constantsService: ConstantsService,
        private clientService: ClientService,
        private deviceService: DeviceService,
        private roomService: RoomService,
        private dailyReadingsService: DailyReadingsService,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit() {
        let self = this;
        // basic
        $('#m_select2_1').select2({
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_1').on('select2:select', function (e) {
            // Do something
            var apartment_name = e.params.data.text;
            self.populateAllRooms(apartment_name);
        });

        $('#m_select2_2').select2({
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_2').on('select2:select', function (e) {
            // Do something
            var room_name = e.params.data.text;
            console.log('selected room name = ' + room_name);
        });

        $('#m_select2_3').select2({
            allowClear: false,
            width: '100%',
            minimumResultsForSearch: Infinity,
        });

        $('#m_select2_4').select2({
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        $('#m_select2_4').on('select2:select', function (e) {
            // Do something
            var client_name = e.params.data.text;
            console.log('selected client name = ' + client_name);
            self.populateAllApartments();
        });

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                    this.getAllClients();
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                    this.getAllDevices();
                }

                if (me.role == this.constantsService.ROLE_USER) {
                    this.getAllDevices();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            }
        );
    }

    populateAllRooms(apartment_name: string): void {
        this.allRoomsNames = [];
        $('#m_select2_2').val(null).trigger('change');
        $('#m_select2_2').empty();

        let selected_client = '';

        if ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let room_name = this.allRooms[i].name;
            let current_apartment_name = this.allRooms[i].apartmentName;
            if (this.isDeveloper || this.isSuper) {
                if (this.allRooms[i].client == selected_client && current_apartment_name == apartment_name
                    && this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            } else {
                if (current_apartment_name == apartment_name && this.allRoomsNames.indexOf(room_name) == -1) {
                    this.allRoomsNames.push(room_name);
                }
            }
        }

        let self = this;
        $('#m_select2_2').select2({
            data: self.allRoomsNames,
            placeholder: 'Select room',
            allowClear: false,
            width: '100%',
        });
    }

    populateAllApartments(): void {
        this.allApartmentsNames = [];
        $('#m_select2_1').val(null).trigger('change');
        $('#m_select2_1').empty();

        let selected_client= '';

        if  ($('#m_select2_4').select2('data').length > 0) {
            selected_client = $('#m_select2_4').select2('data')[0].text;
        }

        for (let i = 0; i < this.allRooms.length; i++) {
            let apartment_name = this.allRooms[i].apartmentName;
            if (this.isDeveloper || this.isSuper) {
                // we have to check first if room belongs to selected client
                if (this.allRooms[i].client == selected_client && this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            } else {
                if (this.allApartmentsNames.indexOf(apartment_name) == -1) {
                    this.allApartmentsNames.push(apartment_name);
                }
            }
        }

        let self = this;
        $('#m_select2_1').select2({
            data: self.allApartmentsNames,
            placeholder: 'Select apartment',
            allowClear: false,
            width: '100%',
        });

        if ($('#m_select2_1').select2('data').length > 0) {
            let selected_apartment = $('#m_select2_1').select2('data')[0].text;
            if (selected_apartment) {
                this.populateAllRooms(selected_apartment);
            }
        } else {
            this.populateAllRooms('');
        }
    }

    populateAlllClients(): void {
        this.allClientsNames = [];
        $('#m_select2_4').val(null).trigger('change');
        $('#m_select2_4').empty();

        for (let i = 0; i < this.allClients.length; i++) {
            let client_name = this.allClients[i].clientName;
            if (this.allClientsNames.indexOf(client_name) == -1) {
                this.allClientsNames.push(client_name);
            }
        }

        let self = this;
        $('#m_select2_4').select2({
            data: self.allClientsNames,
            placeholder: 'Select client',
            allowClear: false,
            width: '100%',
        });

        this.getAllDevices();
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                this.populateAlllClients();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                this.populateAlllClients();
                console.log('clients empty');
            }
        );
    }

    getAllDevices(): void {
        this.deviceService.getAllDevices().subscribe(
            (response) => {
                this.allDevices = response.value;
                this.getAllRooms();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allDevices = [];
                this.getAllRooms();
            }
        );
    }

    getAllRooms(): void {
        this.roomService.getAllRooms().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allRooms = response.value;
                this.populateAllApartments();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allRooms = [];
                this.allApartmentsNames = [];
                this.populateAllApartments();
            }
        );
    }

    checkChartToggle(event: any) {
        if (this.toggle_chart) {
            $('#m_table_2_parent').hide();
        } else {
            $('#m_table_2_parent').show();
        }
    }

    public report() {
        console.log("report is called");
        this.allMSCReadings  = [];
        this.getMSCReadings();
    }

    InitMscTable(): void {
        console.log(this.allMSCReadings);
        let self = this;
        // begin first table
        this.mSCDatatable = $('#m_table_2').DataTable({
            data: this.allMSCReadings,
            responsive: true,
            // paging: true,
            //== DOM Layout settings
            dom:
                "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                lengthMenu: 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function (thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                      <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                          <input type="checkbox" value="" class="m-group-checkable">
                          <span></span>
                      </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: 'id',
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <label id="` +
                            full.id +
                            `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`
                        );
                    },
                },
                {
                    targets: 1,
                    data: 'mac_address',
                    title: 'Mac Address',
                    className: 'text-center',
                },
                {
                    targets: 2,
                    data: 'mac_address',
                    title: 'Name',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_name(data) + '</div>';
                    },
                },
                {
                    targets: 3,
                    data: 'timestamp',
                    title: 'timestamp',
                    render: function (data, type, full, meta) {
                        let dateISOString = data;
                        let date = new Date(dateISOString);
                        date = new Date(
                            date.getTime() + date.getTimezoneOffset() * 60000
                        );
                        // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        let options = {};
                        return (
                            '<span>' +
                            date.toLocaleString([], options) +
                            '</span>'
                        );
                    },
                },
                {
                    targets: 4,
                    data: 'mac_address',
                    title: 'Gateway',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + self.get_gateway(data) + '</div>';
                    },
                },
                {
                    targets: 5,
                    data: 'operation_time_minutes',
                    title: 'Operation Time',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + (data / 60).toFixed(2) + ' hours</div>';
                    },
                },
                {
                    targets: 6,
                    data: 'saving_time_minutes',
                    title: 'Saving Time',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + (data / 60).toFixed(2) + ' hours</div>';
                    },
                },
                {
                    targets: 7,
                    data: 'auto_off_counter',
                    title: 'Auto OFF',
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return '<div>' + data + '</div>';
                    },
                },
                {
                    targets: 8,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: 'text-center',
                    render: function (data, type, full, meta) {
                        return (
                            `
                          <a href="javascript:void(0);" id="` +
                            full.id +
                            `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                            <i class="la la-trash"></i>
                          </a>`
                        );
                    },
                },
            ],
        });

        this.mSCDatatable.on(
            'change',
            '.m-group-checkable',
            function () {
                var set = $(this)
                    .closest('table')
                    .find('td:first-child .m-checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    } else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });

                if (checked) {
                    for (let i = 0; i < self.allMSCReadings.length; i++) {
                        self.selectedMscReadings[i] =
                            self.allMSCReadings[i].id;
                    }
                } else {
                    self.selectedMscReadings = [];
                }
            }
        );

        this.mSCDatatable.on(
            'change',
            'tbody tr .m-checkbox',
            function () {
                let id = $(this).attr('id');

                let isChecked = $(this)
                    .parents('tr')
                    .find('td:first-child .m-checkable')
                    .is(':checked');

                if (isChecked) {
                    //return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscReadings);
                    if (found < 0) {
                        // Element was not found, add it.
                        self.selectedMscReadings.push(id);
                    }
                } else {
                    // return index of element, if not found it will return -1
                    let found = $.inArray(id, self.selectedMscReadings);
                    if (found >= 0) {
                        // Element was found, remove it.
                        self.selectedMscReadings.splice(found, 1);
                    }
                }
            }
        );

        $('#m_table_2').on('click', 'a.m-portlet__nav-link', function () {
            let id = $(this).attr('id');
            self.DeleteMSCReading(id);
        });

        // this.mSCDatatable.on(
        //     'init.dt',
        //     function () {
        //         console.log('datatable init')
        //     }
        // );
    }

    private get_apartment(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].apartment;
            }
        }
        return '';
    }

    private get_room(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].room;
            }
        }
        return '';
    }

    private get_gateway(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].gateway;
            }
        }
        return '';
    }

    private get_name(mac_address: string): string {
        for (let i = 0; i < this.allDevices.length; i++) {
            if (this.allDevices[i].mac_address == mac_address) {
                return this.allDevices[i].name;
            }
        }
        return '';
    }

    public DeleteMSCReading(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover saving again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_2', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.dailyReadingsService.deleteMscReading(id).subscribe(
                    (response) => {
                        self.getMSCReadings();
                        // Swal(
                        //     'Deleted!',
                        //     'Log has been deleted.',
                        //     'success'
                        // );
                    },
                    (err) => {
                        self.getMSCReadings();
                        Swal('Cancelled', err._body, 'error');
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    public DeleteMSCLogs(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            );

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those readings again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                mApp.block('#m_table_2', {
                    overlayColor: '#000000',
                    type: 'loader',
                    state: 'success',
                    message: 'Please wait...',
                });
                self.dailyReadingsService
                    .deleteMscReadings(self.selectedMscReadings)
                    .subscribe(
                        (response) => {
                            self.getMSCReadings();
                            // Swal(
                            //     'Deleted!',
                            //     'Reading has been deleted.',
                            //     'success'
                            // );
                        },
                        (err) => {
                            self.getMSCReadings();
                            Swal(
                                'Cancelled',
                                'Error occured and readings were not deleted',
                                'error'
                            );
                        }
                    );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        });
    }

    private getMSCReadings() {
        // Get the apartment name
        let selected_apartment = $('#m_select2_1').select2('data')[0].text;
        // Get the room name
        let selected_room = $('#m_select2_2').select2('data')[0].text;
        // Get the period required
        let selected_period = $('#m_select2_3').select2('data')[0].id;
        // Get the period required
        var selected_client = null;
        if (this.isDeveloper || this.isSuper)
        {
            if ($('#m_select2_4').select2('data').length > 0)
            {
                selected_client = $('#m_select2_4').select2('data')[0].id;
            }
        }

        console.log(selected_apartment);
        console.log(selected_room);
        console.log(selected_period);
        console.log(selected_client);

        mApp.block('#m_table_2', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...',
        });

        this.dailyReadingsService
            .getAllMSCReadings(selected_apartment, selected_room, selected_period, selected_client)
            .subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.allMSCReadings = response.value.savings;
                    mApp.unblock('#m_table_2');
                    if (this.mSCDatatable) {
                        this.mSCDatatable.clear();
                        this.mSCDatatable.destroy();
                        // this.mSCDatatable.draw();
                    }
                    this.InitMscTable();
                    this.msc_chart_url_savings = this.sanitizer.bypassSecurityTrustResourceUrl(response.value.chart_url_savings);
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.allMSCReadings = [];
                    mApp.unblock('#m_table_2');
                    if (this.mSCDatatable) {
                        this.mSCDatatable.clear();
                        this.mSCDatatable.destroy();
                        // this.mSCDatatable.draw();
                    }
                    this.InitMscTable();
                    this.msc_chart_url_savings = this.sanitizer.bypassSecurityTrustResourceUrl("");
                }
            );
    }
}
