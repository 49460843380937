import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { ConstantsService } from '../../services/utils/constants.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
    public allNotifications: any[] = [];
    private datatable: any;
    public selectedNotifications: any[] = [];
    public currentSelectedNotification: any = {};

    constructor(private notificationsService: NotificationsService, private constantsService: ConstantsService) { }

    ngOnInit() {
        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        this.getAllNotifications();
    }

    getAllNotifications(): void {
        this.notificationsService.getAllNotificationss().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allNotifications = response.value;
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allNotifications = [];
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            });
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.allNotifications,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom: "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                'lengthMenu': 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function(thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                        <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                            <input type="checkbox" value="" class="m-group-checkable">
                            <span></span>
                        </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: "id",
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function(data, type, full, meta) {
                        return `
                          <label id="` + full.id + `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                              <input type="checkbox" value="" class="m-checkable">
                              <span></span>
                          </label>`;
                    },
                },
                {
                    targets: 1,
                    data: "client",
                    title: "Client",
                    className: "text-center"
                },
                {
                    targets: 2,
                    data: "timestamp",
                    title: "Timestamp",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        let dateISOString = data
                        let date = new Date(dateISOString);
                        let options = {
                            weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
                            hour12: true, hour: '2-digit', minute: '2-digit', second: '2-digit'
                        };
                        return '<span>' + date.toLocaleString([], options) + '</span>';
                    },
                },
                {
                    targets: 3,
                    data: "channel",
                    title: "Channel",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Log', 'class': 'm-badge--warning' },
                            1: { 'title': 'Report', 'class': ' m-badge--danger' },
                            2: { 'title': 'News', 'class': ' m-badge--info' },
                            3: { 'title': 'Broadcast', 'class': ' m-badge--success' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.NOTIFICATIONS_CHANNEL_LOG) {
                            location = 0;
                        } else if (data == self.constantsService.NOTIFICATIONS_CHANNEL_REPORT) {
                            location = 1;
                        } else if (data == self.constantsService.NOTIFICATIONS_CHANNEL_NEWS) {
                            location = 2;
                        } else {
                            location = 3;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 4,
                    data: "level",
                    title: "Level",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'critical', 'class': 'm-badge--danger' },
                            1: { 'title': 'debug', 'class': ' m-badge--primary' },
                            2: { 'title': 'error', 'class': ' m-badge--danger' },
                            3: { 'title': 'information', 'class': ' m-badge--info' },
                            4: { 'title': 'none', 'class': ' m-badge--secondary' },
                            5: { 'title': 'trace', 'class': ' m-badge--accent' },
                            6: { 'title': 'warning', 'class': ' m-badge--warning' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == self.constantsService.NOTIFICATIONS_LEVEL_CRITICAL) {
                            location = 0;
                        } else if (data == self.constantsService.NOTIFICATIONS_LEVEL_DEBUG) {
                            location = 1;
                        } else if (data == self.constantsService.NOTIFICATIONS_LEVEL_ERROR) {
                            location = 2;
                        } else if (data == self.constantsService.NOTIFICATIONS_LEVEL_INFORMATION) {
                            location = 3;
                        } else if (data == self.constantsService.NOTIFICATIONS_LEVEL_NONE) {
                            location = 4;
                        } else if (data == self.constantsService.NOTIFICATIONS_LEVEL_TRACE) {
                            location = 5;
                        } else {
                            location = 6;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 5,
                    data: "gateway_mac_address",
                    title: "Gateway",
                    className: "text-center"
                },
                {
                    targets: 6,
                    data: "device_mac_address",
                    title: "Device",
                    className: "text-center"
                },
                {
                    targets: 7,
                    data: "pushEmail",
                    title: "Email",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'True', 'class': 'm-badge--success' },
                            1: { 'title': 'False', 'class': ' m-badge--danger' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;
                        if (data == true) {
                            location = 0;
                        } else {
                            location = 1;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 8,
                    data: "tags",
                    title: "Tags",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        let return_value = '';
                        for (let i = 0; i < data.length; i++) {
                            let tag = data[i];
                            var status = {
                                0: { 'title': 'Developer', 'class': 'm-badge--primary' },
                                1: { 'title': 'Super', 'class': 'm-badge--success' },
                                2: { 'title': 'Admin', 'class': 'm-badge--metal' },
                                3: { 'title': 'User', 'class': ' m-badge--danger' },
                            };
                            // if (typeof status[data] === 'undefined') {
                            //     return data;
                            // }
                            let location: number = 0;
                            if (tag == self.constantsService.NOTIFICATIONS_TAG_DEVELOPER) {
                                location = 0;
                            } else if (tag == self.constantsService.NOTIFICATIONS_TAG_SUPER) {
                                location = 1;
                            } else if (tag == self.constantsService.NOTIFICATIONS_TAG_ADMIN) {
                                location = 2;
                            } else {
                                location = 3;
                            }
                            return_value = return_value + '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                            if (i != (data.length - 1)) {
                                // not last iteration
                                return_value = return_value + '<br/><br/>';
                            }
                        }
                        return return_value;
                    },
                },
                {
                    targets: 9,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        return `
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Show">
                              <i class="la la-eye"></i>
                            </a>
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                              <i class="la la-trash"></i>
                            </a>`;
                    },
                }
            ],
        });

        this.datatable.on('change', '.m-group-checkable', function() {
            var set = $(this).closest('table').find('td:first-child .m-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function() {
                if (checked) {
                    $(this).prop('checked', true);
                    $(this).closest('tr').addClass('active');
                }
                else {
                    $(this).prop('checked', false);
                    $(this).closest('tr').removeClass('active');
                }
            });

            if (checked) {
                for (let i = 0; i < self.allNotifications.length; i++) {
                    self.selectedNotifications[i] = self.allNotifications[i].id;
                }
            } else {
                self.selectedNotifications = [];
            }
        });

        this.datatable.on('change', 'tbody tr .m-checkbox', function() {
            let id = $(this).attr('id');

            let isChecked = $(this).parents('tr').find('td:first-child .m-checkable').is(':checked');

            if (isChecked) {
                //return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedNotifications);
                if (found < 0) {
                    // Element was not found, add it.
                    self.selectedNotifications.push(id);
                }
            } else {
                // return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedNotifications);
                if (found >= 0) {
                    // Element was found, remove it.
                    self.selectedNotifications.splice(found, 1);
                }
            }
        });

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function() {
            let id = $(this).attr('id');
            if ($(this).children('.la-eye').length > 0) {
              // show is clicked
              let id = $(this).attr('id');
              self.notificationsService.getNotification(id).subscribe(
                  (response) => {
                      if (response.value != null) {
                          self.currentSelectedNotification = response.value;
                          let timestampISOString = self.currentSelectedNotification.timestamp
                          let timestamp = new Date(timestampISOString);
                          timestamp = new Date(timestamp.getTime() + (timestamp.getTimezoneOffset() * 60000));
                          self.currentSelectedNotification.timestamp = timestamp;
                          $('#m_modal_1').modal('show');
                      }
                  }, (err) => {
                      self.getAllNotifications();
                      Swal(
                          'Cancelled',
                          'Error occured!',
                          'error'
                      )
                  }
              );
            } else if ($(this).children('.la-trash').length > 0) {
                self.DeleteNotification(id);
            }
        });
    }

    public DeleteNotification(id: string): void {
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover notification again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.notificationsService.deleteNotification(id).subscribe(
                    (response) => {
                        self.getAllNotifications();
                        // Swal(
                        //     'Deleted!',
                        //     'Notification has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllNotifications();
                        Swal(
                            'Cancelled',
                            err._body,
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

    public DeleteNotifications(): void {
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those notifications again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.notificationsService.deleteNotifications(self.selectedNotifications).subscribe(
                    (response) => {
                        self.getAllNotifications();
                        // Swal(
                        //     'Deleted!',
                        //     'Notifications has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllNotifications();
                        Swal(
                            'Cancelled',
                            'Error occured and notifications were not deleted',
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }
}
