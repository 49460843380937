import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    getAllDevices(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/device/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteDeviceId(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/device/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteDeviceMacAddress(mac_address: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/device/mac_address' + mac_address,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteDevices(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/device/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getLocalDevice(mac_address: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if (!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES) {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/device/local/mac_address/' + mac_address,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    postLocalDevice(parameters: any, update_group: number): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('update_group', update_group.toString());
                if (parameters.macaddr) {
                    formData.append('macaddr', parameters.macaddr);
                }

                if (update_group == 0)
                {
                    if (parameters.night_mode != null && (parameters.night_mode == true || parameters.night_mode != 0)) {
                        formData.append('night_mode', "1");
                    } else {
                        formData.append('night_mode', "0");
                    }
    
                    if (parameters.night_mode_start_time_h) {
                        formData.append('night_mode_start_time_h', parameters.night_mode_start_time_h);
                    }
    
                    if (parameters.night_mode_start_time_m) {
                        formData.append('night_mode_start_time_m', parameters.night_mode_start_time_m);
                    }
    
                    if (parameters.night_mode_stop_time_h) {
                        formData.append('night_mode_stop_time_h', parameters.night_mode_stop_time_h);
                    }
    
                    if (parameters.night_mode_stop_time_m) {
                        formData.append('night_mode_stop_time_m', parameters.night_mode_stop_time_m);
                    }
    
                    if (parameters.mode) {
                        if (parameters.mode == 'smart') {
                            formData.append('mode', "1");
                        } else {
                            formData.append('mode', "0");
                        }                    
                    }
    
                    formData.append('skip_auto_fan', parameters.skip_auto_fan);
    
                    if (parameters.fan_timer_mins) {
                        formData.append('fan_timer_mins', parameters.fan_timer_mins);
                    }
    
                    formData.append('skip_changing_temperature', parameters.skip_changing_temperature);
    
                    if (parameters.temperature_offset_timer_mins) {
                        formData.append('temperature_offset_timer_mins', parameters.temperature_offset_timer_mins);
                    }
    
                    if (parameters.temperature_offset) {
                        formData.append('temperature_offset', parameters.temperature_offset);
                    }
    
                    formData.append('allow_auto_off', parameters.allow_auto_off);
    
                    if (parameters.off_timer_mins) {
                        formData.append('off_timer_mins', parameters.off_timer_mins);
                    }
                }
                else if (update_group == 1)
                {
                    if (parameters.ac_temp) {
                        formData.append('ac_temp', parameters.ac_temp);
                    }
                }
                else if (update_group == 2)
                {
                    if (parameters.ac_mode) {
                        if (parameters.ac_mode == 'cool') {
                            formData.append('ac_mode', "0");
                        } else if (parameters.ac_mode == 'heat') {
                            formData.append('ac_mode', "1");
                        }
                    }
                }
                else if (update_group == 3)
                {
                    if (parameters.enabled) {
                        formData.append('enabled', "1");
                    } else {
                        formData.append('enabled', "0");
                    }
                }
                else if (update_group == 4)
                {
                    if (parameters.ac_switch) {
                        formData.append('ac_switch', "1");
                    } else {
                        formData.append('ac_switch', "0");
                    }
                }

                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/device/local/mac_address', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
